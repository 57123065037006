<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Signature
      </h3>
      <hr class="flex-1 border-primary mx-4"/>

    </div>

    <div class="flex flex-col md:flex-row w-full">

      <div class="w-full md:w-4/12 p-2 ">
        <label class="uppercase font-semibold text-sm mb-1 block">Staff Signature</label>
        <div class="w-full">
          <Signature :uploadRefId="staff.staff_id"
                     :uploadSignatureF="uploadSignature"
                     :getSignatureF="getSignature"
                     :visible="visible"
                     class="w-full bge-input bge-input-spacing rounded">
          </Signature>
        </div>
      </div>

      <div class="w-full md:w-4/12 p-2 mb-4 md:mb-0">

      </div>


      <div class="w-full md:w-4/12 p-2 mb-4 md:mb-0">

      </div>


    </div>

  </div>
</template>

<script>

import Signature from "@/components/Signature.vue";

const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "Staff-Signature",
  components: {
    Signature,
    IsAuthorized,
  },
  props: {
    staff: Object,
    visible: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    async uploadSignature(files, refId, isSign = true) {
      const result = await this.StaffService.createStaffDocument(refId, files[0], isSign);
      return [result];
    },
    async getSignature(refId, category) {
      return this.StaffService.getStaffDocumentsByDocRole(refId, 'signature');
    },
  },
};
</script>
